@media (max-width: 1000px) {
    .rdrDateRangePickerWrapper {
        flex-direction: column-reverse;
        align-items: center;
    }
    .rdrDefinedRangesWrapper {
        width: 352px;
        border-right: none;
    }
    .rdrMonthName {
        border-top: solid 1px #eff2f7;
    }
}
